import { PodcastIcon } from 'components/icons/podcast'
import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'
import { getOptionValue } from '../../../constants'

interface AddPodcastState {
  error?: string,
  existingSources: any[],
  feed: any,
  found: boolean,
  isLoading: boolean,
  name: string,
  selectedCommunity: string,
  url: string,
  isSourceGated: boolean,
}

class ContentPoolAddPodCastSourceComponent extends RasaReactComponent<any, AddPodcastState> {
  public static contextType = RasaContext;
  constructor(props: any) {
    super(props, 'communitySource', {
      existingSources: [],
      feed: {},
      found: true,
      isLoading: false,
      name: '',
      selectedCommunity: '',
      url: '',
      isSourceGated: false,
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.loadRecord(activeCommunity.communityId, null)
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          this.setState({
            existingSources: response[0] || [],
            selectedCommunity: activeCommunity.communityId,
          })
        })
    })
  }

  public render = () => {
    return <div className={`${this.context.store.getState().config.isMobile ? 'add-podcast-wrapper-mobile' : 'add-podcast-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add New Podcast Feed</div>
      <p className="section-detail-text">
        Add a podcast url and we’ll find the feed, or add the podcast directly. &nbsp;
        <a href={this.getPodcastHelpUrl()}
           target="_blank" rel="noopener">
          Learn how to find an podcast feed here.
        </a>

      </p>
      {isEmpty(this.state.feed) && <div className="podcast sources">
        <div className="image">
          <PodcastIcon svgwidth="48" svgheight="48"/>
        </div>
        <div className="words">
          <h2>Podcast feed</h2>
          <p>Enter a podcast feed URL</p>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.searchSource(this.state))}>
              <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                    value={this.state.url}
                    onChange={(e: any) => this.setState({ found: true, url: e.target.value })}/>
              <Button>Next</Button>
            </form>
          </div>
          {!this.state.found &&
            <span className="warning" dangerouslySetInnerHTML={{__html: this.state.error || 'We could not locate a feed for this URL'}} />
          }
        </div>
      </div>}
      {!isEmpty(this.state.feed) && <div className="">
        <div className="podcast confirm sources">
          <div className="image">
            <PodcastIcon/>
          </div>
          <div className="words">
            <h2>Podcast feed</h2>
            <p>
              <strong>We found your source, {this.state.feed.url}.</strong>
            </p>
            <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
            {!isValidSourceName(this.state.existingSources, this.state.name) &&
            <div className="invalid-source-name-container">You already have a source with this name</div>}
            <div className="input-area">
              <form onSubmit={preventDefault()}>
                <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e: any) => this.setState({ name: e.target.value })}/>
                <Button onClick={() => this.addSource({
                  community_id: this.context.user.activeCommunity.communityId,
                  identifier: this.state.feed.url,
                  name: this.state.name,
                  type: SourceTypes.podcast})}
                  disabled={this.state.isLoading ||
                    !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
                <Button className="change" onClick={() => this.setState({feed: {}})}>Change</Button>
              </form>
            </div>
          </div>
        </div>
      </div>}
      {this.state.isLoading && <Loading.Loading size={Loading.SIZES.LARGE}/>}
      {!isEmpty(this.state.feed) && this.state.feed.items.length > 0 &&
        <div className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
          {this.state.isSourceGated &&
              <div className="gated-content">This source may contain gated content.</div>
            }
          <h4>Recent Articles</h4>
        {this.state.feed.items.slice(0, 5).map((feed, i) =>
          <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
      </div>}
    </div>
  }

  public searchSource(state) {
    this.setState({isLoading: true}, () =>
      AjaxWrapper
        .ajax(AjaxWrapper.getServerUrl() + '/feed/find', HttpMethod.POST, {
          application: 'dashboard',
          feedUrl: state.url,
          identifier: state.selectedCommunity,
        })
        .then((feed) => this.setState({
          feed,
          found: true,
          isLoading: false,
          name: feed.items.length > 0 ? this.defaultFeedName(feed.items[0]) : feed.title,
        })).then(() => {
          return new Dataset()
          .loadCommunityDataset('sourcesByIdentifier', this.state.selectedCommunity, [
            { param: 'identifier', value: this.state.feed.url },
          ])
          .then((sourcesResults) => {
            if (sourcesResults[0] && sourcesResults[0].length && sourcesResults[0][0].is_gated) {
              this.setState({isSourceGated: true})
            }
          })
        })
        .catch((err) => {
          this.setState({
            error: err.response.message,
            feed: {},
            found: false,
            isLoading: false,
            name: '',
          })
        }))
  }

  public addSource(source) {
    this.props.propertiesChanged(source)
    this.saveRecord(null).then(() => {
      postAddSource(this.context, 'podcast', source.identifier, this.props.push)
    })
  }

  private defaultFeedName = (feed: any): string => {
    if (feed.author && feed.publisher) {
      return `${feed.publisher} - ${feed.author}`
    } else if (feed.author || feed.publisher) {
      return `${feed.author || feed.publisher}`
    } else if (feed.source) {
      return feed.source
    } else {
      return `${feed.feedUrl} Blog`
    }
  }

  private getPodcastHelpUrl = (): string => {
    return getOptionValue('PODCAST_HELP_URL', 'https://help.rasa.io/how-to-find-an-rss-feed-for-a-podcast')
  }
}

export const ContentPoolAddPodcastSource = GenericRedux.createConnect(
  ContentPoolAddPodCastSourceComponent,
  Constants.REDUX_STORE_HOME,
)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(
      Constants.REDUX_STORE_HOME,
      {url: ''},
    ),
  }),
)
