import classnames from 'classnames'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as Utils from 'generic/utility'
import * as GA from 'google-analytics'
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as Redux from 'redux'
import { getActivesSourcesCount } from 'shared/utils'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import { injectReducer } from 'store/index'
import { ADD_SOURCES, ContentLibraryDialog, SEND_GA_EVENT } from './constants'
import * as Types from './types'
import { getOptionValue, HELP_MAIL_TO, SUPPORT_EMAIL } from '../../../constants'

declare const RasaContentTool: any

const ga4Event = (response: Types.RCTResponse): GA.Event4 => {
  return {
    name: GA.EventNames.ContentTool,
    payload: {
      action_type: response.payload.data.action,
      label: response.payload.data.label,
    },
  }
}

class ContentToolComponent extends RasaReactComponent<any, Types.ContentToolComponentState> {
  public static contextType = RasaContext
  private containerId = 'rasa-content-tool-container'

  constructor(props: any) {
    super(props, 'communitySource')

    this.state = {
      isDirty: false,
      isSaving: false,
      isLoading: true,
      rasaContentToolLoadFailed: false,
      savingSources: false,
      showConfirmationModal: true,
    }
  }

  public componentDidMount = () => {
    Promise.all([
      this.context.user.init(),
      this.loadRasaContentTool(),
    ])
    .then(([user, rasaContentToolLoaded]) => {
      if (rasaContentToolLoaded) {
        // successfully loaded RasaContentTool
        const maxAllowedSources = BillingPlan.getCurrentPlanMaxSources(user.activeCommunity.billingInfo.currentPlan)
        const payload: any = {
          callback: this.callback,
          parentId: this.containerId,
          rasaToken: user.token,
          sourcesInfo: {
            activeSourcesCount: getActivesSourcesCount(user),
            maxAllowedSources,
          },
        }
        const queryParams: URLSearchParams = new URLSearchParams(document.location.search)
        if ( queryParams.get('c') ) {
          payload.currentPage = 'contentLibrary'
          payload.selectedCategory = queryParams.get('c')
        }
        RasaContentTool.init(payload)
        RasaContentTool.show()
      } else {
        // couldn't load RasaContentTool in time or error occured
        this.setState({
          rasaContentToolLoadFailed: true,
        })
      }
      this.setState({
        isLoading: false,
        communityId: user.activeCommunity.communityId,
      })
    })
  }

  public render() {
    return (
      <div className="rasa-content-tool-container">
        {this.getContentLibraryModel()}
        {this.state.savingSources && <div className="loading-container"><Loading size="64" /></div>}
        <div id={this.containerId}
             className={classnames('rasa-content-tool', {disabled : this.state.isLoading })}>
        </div>
        {this.state.rasaContentToolLoadFailed && <div className="load-failed">
          There was an error loading content library, please contact &nbsp;
          <a href={`${HELP_MAIL_TO}?subject=Rasa Content Library - Failed`}>Customer Success for assistance</a>
        </div>
        }
      </div>
    )
  }

  private getContentLibraryModel = () => {
    return <div>
    <Modal isOpen={this.state.showConfirmationModal}
      className="archive-confirmation-modal"
      size="lg"
      fade={false}
      centered={true} >
      <ModalHeader>{ContentLibraryDialog.title}</ModalHeader>
      <ModalBody className="archive-confirmation-modal-body">
        <div>
          <div  dangerouslySetInnerHTML={{
            __html: this.description(),
          }}/>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => this.hideConfirmationModal()}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  </div>
  }

  private description = () => {
    const support_email = getOptionValue('SUPPORT_EMAIL', SUPPORT_EMAIL);

    return ContentLibraryDialog.description.replace(/__SUPPORT_EMAIL__/g, support_email)
  }

  private hideConfirmationModal = () => {
    this.setState({showConfirmationModal: false })
  }

  private loadRasaContentTool() {
    const maxWaitTime = 10000
    let timeElapsed = 0
    const interval = 100

    function loader(resolve, reject) {
      timeElapsed = timeElapsed + interval
      if (timeElapsed > maxWaitTime) {
        resolve(false)
      } else {
        setTimeout(() => {
          if (!Utils.isRasaContentToolLoaded()) {
            loader(resolve, reject)
          } else {
            resolve(true)
          }
        }, interval)
      }
    }

    return new Promise((resolve, reject) => {
      loader(resolve, reject)
    })
  }

  private addSources = (response: Types.RCTResponse) => {
    if (response.success) {
      this.setState({
        communityId: this.context.user.activeCommunity.communityId,
        savingSources: true,
      }, () => {
        const data = response.payload ? response.payload.data : response.sources
        this.processArrayInSync((data || []), this.saveSource)
        .then(() => {
          this.setState({savingSources: false})
          this.props.push(`${DashboardMenuOption.content}/sources`)
        })
      })
    } else {
      // TODO: some error occured?
    }
  }

  private callback = (response: Types.RCTResponse) => {
    // backward compatibility
    if (!response.payload) {
      this.addSources(response)
    } else {
      switch (response.payload.type) {
        case ADD_SOURCES:
          this.addSources(response)
          break
        case SEND_GA_EVENT:
          if (response.success) {
            // send the GA event
            this.context.store.dispatch(GA.sendGa4Event(ga4Event(response)))
          }
          break
        default:
          break
      }
    }
  }

  private processArrayInSync(array, fn) {
    const results = [];
    return array.reduce((p, item) => {
      return p.then(() => {
        return fn(item).then((data) => {
          results.push(data);
          return results;
        })
      })
    }, Promise.resolve())
  }

  private saveSource = (source: Types.Source) => {
    this.props.propertiesChanged({
      ...source,
      community_id: this.context.user.activeCommunity.communityId,
    })

    return this.saveRecord(null)
      .then(() => {
        const event: GA.Event4 = {
          name: GA.EventNames.AddSource,
          payload: {
            action_type: 'content_tool',
            identifier: source.identifier,
          },
        }
        this.context.store.dispatch(GA.sendGa4Event(event))
        return
      })
  }
}

const contentTool = 'content_tool'
export const ContentTool = GenericRedux.createConnect(ContentToolComponent, contentTool)
injectReducer(
  contentTool,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(contentTool, {}),
  }),
)
